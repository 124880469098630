<template>
	<!-- 公告详情 -->
    <div class="notice-detail">
		<!-- 自定义头部 -->

		<div class="main">
			<div class="title">
				{{detailObj.title}}
			</div>
			<div class="time color-999">
				{{detailObj.createTime}}
			</div>
			<div class="desc" v-html="detailObj.details">
			</div>
		</div>
	</div>
</template>

<script>
import { seeNoticeSystem } from "@/api/message";
import { timestampFormat } from "@/utils/index";
export default {
	components: {
	},
	data() {
		return {
			headerTitle:"公告详情",
			backVisible:true,
			// describe:`<p style='color:#222;line-height:26px'>平台通知内容平台通知内容平台
			// 		通知内容平台通知内容平台通知内容平台通知内容平台通知内容</p>`,
			detailObj:{},
		}
	},	
	methods: {
		
	},
	created(){
		let id = this.$route.query.id;
		// let time = timestampFormat(Date.parse(this.time)/1000);
		seeNoticeSystem({id}).then(res=>{
			let {status,data} = res;
			if(status){
				// data.createTime = timestampFormat(data.createTime);
				// console.log('data.createTime---',data);
				this.detailObj =  data;
			}
		})

	}
};
</script>

<style scoped>
div{
	box-sizing: border-box;
}
.notice-detail{
    padding-top: 50px;
}
.notice-detail .main{
	padding: 40px 20px;
	
}
.notice-detail .main .title{
	font-size: 25px;
	font-weight: 500;
}
.notice-detail .main .time{
	font-size: 14px;
	margin-top: 12px;
	margin-bottom: 28px;
}
.notice-detail .main .desc{

}
</style>